@media screen and (max-width: 700px) {
    h2 i.material-icons {
        left: inherit;
        position: relative;
        font-size: 1.7rem;
        line-height: 15px;
        display: inline-block;
        top: 3px;
    }
    .form-compressed{
        max-width: 100%;
    }

    .brand_container li:nth-child(1n) {
        margin-right:10px;
        margin-bottom: 10px;
        font-weight:bold;
    }
    .brand_container li:nth-child(1n+1) {
        margin-left:10px;
        margin-bottom: 10px;
        font-weight:bold;
    }
    
    .brand_container ul {
        padding: 0 0px;
    }
    .img-border{
        max-width: '100%';
    }

}


@media screen and (max-width: 440px){
    .title{
        
        font-size: 1.4rem;

    }
    
    span.bigger {
        font-size: 170%;
    }
    .topbar{
        height: 70px;
    }
    .topbar .logo{        
        width: 100%;
        height: 40px;        
        background-size: 50% auto;
        left: 0;
        top: 15px;
    }
    .topbar .links{
        display: none;
    }

    .homeImageContainer {
        margin: 20px 0;
    }

    .container{
        padding: 0 10px;
    }

    .brand_container li {
        width: 40%;
    }

    .gommeMotoContainer .container {
        width: 100%;
    }
    .gommeAutoContainer .container, .gommeMotoContainer .container{
        width: 100%;
        padding: 20px 10px;
    }

    .mobileMenuContainer{
        display: block;
        top: 10px;
    }

    
    .col{
        flex-basis: inherit;
    }

}