/* override css bootstrap */
.fade:not(.show){
  opacity: inherit;
}
.arrows{
  display: none;
}
.globalContainer {overflow: hidden;}
body {
  margin: 0;
  font-family: 'Yantramanav', sans-serif;
  font-weight: 300;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #efefef;
  font-size: 1.1rem;
  color:#4f4f4f;
}
a{
  color:rgb(181, 0, 0);
}
a:hover{
  color:rgb(244, 24, 24);
}
a:active{
  color:rgb(128, 8, 8);
}
h1, h2, h3, h4{
  font-weight: 900;
  position: relative;
}
h2 i.material-icons {
  position: absolute;
  left: -45px;
  font-size: 2.4rem;
}







.App {
  text-align: center;
  
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

/* FOOTER */

.footer a, .subfooter a{
  color:#FFF;
}
.subfooter{
  background: #3f3f3f;
    text-align: center;
    padding: 40px 20px 20px 20px;
    color: #FFF;
    font-size: 80%;
}

.footer{
  padding: 50px 0;
  color: #FFF;
  font-size: 90%;
  min-height: 450px;
  background: #454545;
}
.footer_col_sx{
  margin-bottom: 50px;
}
.footer_col_sx img{
  width: 100%;
  max-width: 180px;
}
.footer div.active a {
  text-decoration: underline;
}


/* SIDEBAR */
div#sidebar {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 500;
  background: rgba(0, 0, 0, 0.79);
  -webkit-transition: all 0.2s ease-in;
  -moz-transition:    all 0.2s ease-in;
  -ms-transition:     all 0.2s ease-in;
  -o-transition:      all 0.2s ease-in;
  transition:         all 0.2s ease-in;  
  right: 0;
}

.sidebar_closer i {
  /* background-color: inherit; */
  font-size: 4rem;
  color:#FFF;
}

.sidebar_closer {
  background: none;
    padding: 20px;
    text-align: right;
}

div#sidebar.hidden {
  right: -100%;
}
div#sidebar .container ul {
  list-style: none;
  color: #FFF;
  text-transform: uppercase;
  font-weight: 900;
  text-align: right;
  padding: 0 20px;
  font-size: 2rem;
}
div#sidebar .container ul li a{
  color:#FFF;
}
div#sidebar .container ul li.active a{
  color:red;
}

/* TOPBAR */

.topbar {
  background-color: #393939;
  position: relative;
  height: 100px;
  top: 0;
  width: 100%;
  z-index: 50;
  -webkit-transition: all 0.2s ease-in;
  -moz-transition: all 0.2s ease-in;
  -ms-transition: all 0.2s ease-in;
  -o-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
  
}

.topbar a {
  color: #FFF;
  text-decoration: none;
  font-size: 14px;
}

.topbar .logo {
  position: absolute;
  top: 20px;
  left: 12px;  
  -webkit-transition: all 0.2s ease-in;
  -moz-transition: all 0.2s ease-in;
  -ms-transition: all 0.2s ease-in;
  -o-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
  width: 450px;
  height: 60px;
  background: url(/images/il-gommista-insegna-stmp-copia-2.png) left no-repeat;
  background-size: auto 100%; 
  background-position-x: 10px;
  z-index: 50;
}

.topbar .links {
  position: absolute;
  right: 10px;
  top: 35px;
  -webkit-transition: all 0.2s ease-in;
  -moz-transition: all 0.2s ease-in;
  -ms-transition: all 0.2s ease-in;
  -o-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
}
.topbar .links li {
  margin-right: 15px;
  display: inline-block;
}

.topbar .links li.active a{
  color: red;
}

.topbar.minified .logo {height: 40px; top: 15px}
.topbar.minified .links {
    top: 20px;
}
.topbar.minified {
    height: 70px;
    position: fixed;
}
.mobileMenuContainer {
  position: absolute;
  /* color: #fff; */
  right: 10px;
  top: 20px;
  z-index: 90;
  display: none;
}

.mobileMenuContainer i {
  color: #FFF;
  font-size: 3rem;
}
.mobileMenuContainer i:hover{
  cursor: pointer;
}




/* PAGE */


.title{
  font-family: 'Oxygen', sans-serif;
  font-size: 2rem;
  font-weight: bold;
}
span.bigger {
  font-size: 200%;
}

.SlideShowContainer{
  height: 450px;
  position: relative;
  width: 100%;
  border-bottom: 5px solid #656565;
}

.gradient1 {
  padding: 40px 0;
  background: #ffffff; /* Old browsers */
  background: -moz-linear-gradient(top, #ffffff 17%, #f9f9f9 69%, #e8e8e8 97%, #e8e8e8 97%, #dbdbdb 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top, #ffffff 17%,#f9f9f9 69%,#e8e8e8 97%,#e8e8e8 97%,#dbdbdb 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, #ffffff 17%,#f9f9f9 69%,#e8e8e8 97%,#e8e8e8 97%,#dbdbdb 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#dbdbdb',GradientType=0 ); /* IE6-9 */
}

.homeImageContainer {
  position: relative;
  height: 300px;
  overflow: hidden;
  margin: 40px 0;
  border-radius: 2px;
}

.homeImageContainer img {
  position: absolute;
  width: 100%;
  transform: translateY(-50%);
  top: 50%;
}

.gommeAutoContainer {
  min-height: 500px;
  background: #000 url(/images/auto-tyres.min.jpg) center no-repeat;
  background-size: cover;
  animation-fill-mode: backwards;
  border-bottom:10px solid #000;
  color:#FFF;
}
.gommeCerchiContainer {
  min-height: 200px;
  background: #000 url(/images/alloy_wheel.jpg) center no-repeat;
  background-size: cover;
  animation-fill-mode: backwards;
  border-bottom:10px solid #000;
  color:#FFF;
}
.gommeMotoContainer {
  min-height: 200px;
  background: #000 url(/images/moto-tyres.min.jpg) center no-repeat;
  background-size: cover;
  animation-fill-mode: backwards;
  color:#FFF;
  border-bottom:10px solid #000;
}

.gommeAutoContainer .container, .gommeMotoContainer .container, .gommeCerchiContainer .container{
  padding: 50px 0;
}
.gommeMotoContainer .container, .gommeCerchiContainer .container {
  width: 60%;
}
.topbarSpacer {
  margin-top: 70px;
}


.brand_container li a {
  color: #FFF;
  text-decoration:none;
}

.brand_container li {
  display: inline-block;
  padding: 10px 20px;
  background-color:rgba(0,0,0,.8);
  border-radius: 4px;
  margin: 0 15px 15px 0;
  -webkit-transition: all 0.2s ease-in;
  -moz-transition: all 0.2s ease-in;
  -ms-transition: all 0.2s ease-in;
  -o-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
}

.brand_container {
  text-align: center;
}

.brand_container li:hover {
  background-color: rgba(255, 255, 255, 0.7);
  border:0;
}
.brand_container li:hover a{
  color: #000;
}
.brand_container {
  margin: 40px 0
}


div#arrowUpWindowManager {
  width: 50px;
  height: 50px;
  background: rgba(255, 255, 255, .8);
  position: fixed;
  z-index: 100;
  bottom: 20px;
  right: 20px;
  border-radius: 50px;
  opacity: 0;
  -webkit-transition: all 0.2s ease-in;
  -moz-transition: all 0.2s ease-in;
  -ms-transition: all 0.2s ease-in;
  -o-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
}
div#arrowUpWindowManager.shown{
  opacity: .8;
} 
div#arrowUpWindowManager i {
  font-size: 50px;
  transform: rotate(170deg);
  -webkit-transition: all 0.23s ease-in;
  -moz-transition: all 0.23s ease-in;
  -ms-transition: all 0.23s ease-in;
  -o-transition: all 0.23s ease-in;
  transition: all 0.23s ease-in;
}

div#arrowUpWindowManager:hover i{
  color:red;
  cursor: pointer;
}

.map{
  width: 100%;
  height: 300px;
}

.form-compressed{
  max-width: 75%;
}
.footer_subcol{
  margin-bottom: 20px;
}

.sidebar_logo{
  position: absolute;
  bottom: 20px;
  width: 100%;
  height: 100px;
  background: url(/images/il-gommista-insegna-stmp-copia-2.png) center no-repeat;
  background-size: 80% auto;
}

div#form-container{
  -webkit-transition: all 0.23s ease-in;
  -moz-transition: all 0.23s ease-in;
  -ms-transition: all 0.23s ease-in;
  -o-transition: all 0.23s ease-in;
  transition: all 0.23s ease-in;
  opacity: 1;
}

div#form-container.hidden {
  opacity: 0;
}

.img-border{
  border: 1px solid rgb(184, 184, 184);
  max-width: 100%;
}

.marginBottom10{
  margin-bottom: 10px;
}

.img-logo-ebike{
  width: 250px;
}
.img-border-radius{
  border-radius: 8px;
}
ul.catalogo-ebike {
  list-style: none;
  padding: 0;
  margin: 0;
}

ul.catalogo-ebike li {
  display: inline-block;
  margin: 0 15px 15px 0;
}

ul.catalogo-ebike li a:hover > img {
  opacity: .8;
}
.logoContainer{
  margin-bottom: 20px;
}
.brandContainer {
  margin-bottom: 90px;
}
.img-max{
  max-width: 100%;
}